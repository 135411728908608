import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ArrowBackIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import {Link} from 'react-router-dom';
import {ToastContainer} from "react-toastify";
import Tooltip from '@material-ui/core/Tooltip';
import processing from '../images/processing.png'
import side from '../images/side.png'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import {useTranslation} from "react-i18next";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    sideBar: {
        backgroundImage: `url(${side})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "bottom"
    },
    sideBarLink: {
        backgroundColor: "rgba(250, 250, 250, 0.8)"
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        // width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(0),
        },
        [theme.breakpoints.up('xs')]: {
            width: theme.spacing(0),
        },

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        backgroundColor: "#f6f6f6"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },

}));
export default function Main({Context, pageTitle = null}) {
    const {t, i18n} = useTranslation();
    const checkLogin = localStorage.getItem('login');

    const mql = window.matchMedia(`(min-width: 800px)`);
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(mql.matches);
    useEffect(() => {
        mql.addListener(mediaQueryChanged);
    }, [])
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const mediaQueryChanged = () => {
        setOpen(mql.matches);
    }
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const roles = localStorage.getItem('roles');
    const AdminNav = () => {
        try {
            if (roles.includes('admin')) {
                return <>
                    <Divider/>
                    <List>
                        <Link to={"/ecu/admin/tuning/types"}>
                            <ListItem className={classes.sideBarLink} button>
                                <Tooltip title="Add" placement="right">
                                    <ListItemIcon> <DynamicFeedIcon/> </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={t('TUNING TYPES')}/>
                            </ListItem>
                        </Link>
                    </List>
                    <Divider/>
                    <List>
                        <Link to={"/ecu/admin/ecus"}>
                            <ListItem className={classes.sideBarLink} button>
                                <Tooltip title="Add" placement="right">
                                    <ListItemIcon> <InsertDriveFileIcon/> </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={t('ECU FILES')}/>
                            </ListItem>
                        </Link>
                    </List>
                </>
            } else {
                return <></>
            }
        } catch (e) {
            return <></>
        }
    }
    const UserNav = () => {
        if (checkLogin != "true") {
            return <>
                <List>
                    <Link to={"/login"}>
                        <ListItem className={classes.sideBarLink} button>
                            <Tooltip title="Add" placement="right">
                                <ListItemIcon> <LockOpenIcon/> </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={t('LOGIN')}/>
                        </ListItem>
                    </Link>
                </List>
                <Divider/>
                <List>
                    <a target="_blank" href={"https://carecusoft.com/register"}>
                        <ListItem className={classes.sideBarLink} button>
                            <Tooltip title="Add" placement="right">
                                <ListItemIcon> <AddCircleIcon/> </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={t('SING UP')}/>
                        </ListItem>
                    </a>
                </List>

            </>
        }
        return <>
            <List>
                <Link to={"/ecu/my/files"}>
                    <ListItem className={classes.sideBarLink} button>
                        <Tooltip title="Add" placement="right">
                            <ListItemIcon> <RecentActorsIcon/> </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={t('MyFILES')}/>
                    </ListItem>
                </Link>
            </List>
            <Divider/>
            <List>
                <Link to={"/ecu/profile"}>
                    <ListItem className={classes.sideBarLink} button>
                        <Tooltip title="Add" placement="right">
                            <ListItemIcon> <AccountBoxIcon/></ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={t('PROFILE')}/>
                    </ListItem>
                </Link>
            </List>
            <Divider/>
            <List>
                <Link to={"/logout"}>
                    <ListItem className={classes.sideBarLink} button>
                        <Tooltip title="Add" placement="right">
                            <ListItemIcon> <ExitToAppIcon/> </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={t('LOGOUT')}/>
                    </ListItem>
                </Link>
            </List>

        </>
    }
    return (
        <div className={`${classes.root} `}>
            <CssBaseline/>
            <Drawer
                variant="persistent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                        [classes.sideBar]: open
                    }),
                }}
                open={open}
            >
                <div className={classes.toolbar}>
                    <img src={processing} width={50}/> <span style={{fontSize: 18}}>{t('ECU File Service')}</span>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </div>
                <AdminNav/>
                <Divider/>
                <List>
                    <Link to={"/ecu/file/form"}>
                        <ListItem className={classes.sideBarLink} button>
                            <Tooltip title="Add" placement="right">
                                <ListItemIcon> <CenterFocusStrongIcon/> </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={t('ONLINE PROCESS')}/>
                        </ListItem>
                    </Link>
                </List>
                <Divider/>
                <UserNav/>

            </Drawer>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon/>
                    </IconButton>

                    <Typography variant="h6" noWrap>
                        {pageTitle}
                    </Typography>
                </Toolbar>
            </AppBar>
            <main className={`${classes.content}`}>
                <br/>
                <br/>
                <br/>
                <br/>
                <Context/>
            </main>
            <ToastContainer/>
        </div>
    );
}
