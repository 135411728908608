import React from 'react';
import {Redirect} from 'react-router-dom';

const Logout = () => {
    localStorage.setItem('login', false);
    localStorage.setItem('token', null);
    localStorage.setItem('roles', []);
    localStorage.setItem('user', {});
    return (<Redirect to="/login"/>)
}

export default Logout;
