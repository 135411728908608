import React, {useState, useEffect} from 'react';
import instance from '../apis';

function useCESLogin() {
    const [cesSes, setCesSes] = useState(true);
    useEffect(() => {
        instance
            .post('/api/ecu/ces/session')
            .then(function (res) {
                setCesSes(res.data)
            })
    },[]);
    return cesSes;
}

export default useCESLogin;
