import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import Check from '@material-ui/icons/Check';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Code from '@material-ui/icons/Code';
import GetApp from '@material-ui/icons/GetApp';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import instance from '../apis';
import Main from './Main';
import {Segment, Dropdown, ListList} from 'semantic-ui-react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import load from '../load.gif';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import List from '@material-ui/core/List';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import DiscFullIcon from '@material-ui/icons/DiscFull';
import Divider from "@material-ui/core/Divider";
import TuningTypes from './TuningTypes';
import {CreditsContext, FileContext, SelectTypesContext} from "../stores/File";
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import useCESLogin from "../hooks/useCESLogin";

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        backgroundColor: 'white'
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
        </div>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});


const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
        backgroundColor: '#fff',
    },
    button: {
        marginRight: theme.spacing(1),
    },

    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    segmentTitle: {
        fontSize: 18,
        fontWeight: 600
    },
    downloadButton: {
        width: "100%",
        backgroundColor: "DodgerBlue",
        border: "none",
        color: "white",
        padding: [12, 30],
        cursor: "pointer",
        fontSize: 20,
        "&:hover": {
            backgroundColor: "RoyalBlue",
        }
    },
}));


export default function FileForm(props) {
    const {t, i18n} = useTranslation();
    const history = props.history;
    const [ecuId, setEcuId] = useState('');
    const [values, setValues] = useState({
        name: 'Cat in the Hat',
        age: '',
        multiline: 'Controlled',
        currency: 'EUR',
        brand_id: '',
        brands: [],
        selectBrands: []
    });
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const [ecuList, setEcuList] = useState([]);
    const [files, setFiles] = useContext(FileContext);
    const [loadFile, setLoadFile] = useState(false);
    const [credits, setCredits] = useContext(CreditsContext)
    const [user, setUser] = useState()
    const [odeLoad, setOdeLoad] = useState(false)
    const [loadDownload, setLoadDownload] = useState(false)
    const [, setSelectTypes] = useContext(SelectTypesContext)

    function getSteps() {
        return [t('Select ECU Files'), t('Edit/Payment'), t('Download')];
    }


    useEffect(() => {

        instance
            .get('/api/ecu/brands')
            .then(function (res) {
                try {
                    const getData = [];
                    res.data.map(item => {
                        getData.push({
                            key: item.id,
                            value: item.id,
                            text: item.var_title
                        })
                    })
                    setValues(values => ({
                        ...values,
                        ['brands']: res.data,
                        ['selectBrands']: getData
                    }));
                } catch (e) {

                }

            })
    }, [])

    function ColorlibStepIcon(props) {
        const classes = useColorlibStepIconStyles();
        const {active, completed} = props;
        const icons = {
            1: <CloudUpload/>,
            2: <Code/>,
            3: <GetApp/>,
        };
        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    ColorlibStepIcon.propTypes = {
        active: PropTypes.bool,
        completed: PropTypes.bool,
        icon: PropTypes.node,
    };


    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function handleReset() {
        setActiveStep(0);
    }

    const Context = () => {
        const handleChanges = (e, {name, value}) => {
            setValues(values => ({...values, [name]: value}))
            instance
                .get(`/api/ecu/eculist/${value}`)
                .then(function (res) {
                    const getData = [];
                    res.data.map(item => {
                        getData.push({
                            key: item.id,
                            value: item.id,
                            text: item.brand + " " + item.model + " " + item.processor,
                        })
                    })
                    setEcuList(getData)
                })
        }

        function handleEcuSelect(e, {value}) {
            setEcuId(value)
        }

        const SelectBrands = () => {
            return <>
                <Grid item xs={12} sm={4}>
                    <Segment>
                        <Dropdown
                            search
                            selection
                            name="brand_id"
                            options={values.selectBrands}
                            onChange={handleChanges}
                            defaultValue={values.brand_id}
                            placeholder={t('Select Brand')}
                        />
                    </Segment>
                </Grid>
            </>
        }
        const EcuListView = () => {
            if (values.brand_id) {
                return <>
                    <Grid item xs={12} sm={4}>
                        <Segment>
                            <Dropdown
                                search
                                selection
                                name="ecuId"
                                options={ecuList}
                                placeholder={t('Select ECU')}
                                onChange={handleEcuSelect}
                                value={ecuId}
                            />
                        </Segment>
                    </Grid>
                </>
            } else {
                return <></>
            }
        }
        const fileDelete = (id) => {
            instance
                .delete(`/api/ecu/file/${id}`)
                .then(function (res) {
                    setFiles({
                        id: null,
                        name: null
                    })
                })
        }

        function handleNext() {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }

        const SelectFileView = () => {
            const selectFile = () => {
                const ecuFile = document.getElementById("ecuFile");
                ecuFile.click()
                ecuFile.onchange = function () {
                    setLoadFile(true)
                    let formData = new FormData();
                    const inputFile = ecuFile.files[0];
                    formData.append("file", inputFile);
                    formData.append("ecu_id", ecuId);
                    formData.append("brand_id", values.brand_id);
                    instance
                        .post('/api/ecu/file', formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                        )
                        .then(function (res) {
                            handleNext()
                            setFiles(res.data)
                            setLoadFile(false)
                            setOdeLoad(false)
                            setLoadDownload(false)
                            setOdeLoad(false)
                            setCredits([])
                            setSelectTypes([])
                        })
                    instance
                        .post('/api/ecu/user')
                        .then(function (res) {
                            setUser(res.data)
                        })
                }
            }


            if (ecuId) {
                const FileItem = () => {
                    if (files.id) {
                        return <>
                            {files.name} <Button onClick={() => fileDelete(files.id)} variant="contained" color="secondary">{t('DELETE')}</Button>
                            <Button onClick={() => handleNext()} variant="contained" color="primary">{t('NEXT')}</Button>
                        </>
                    } else {
                        if (loadFile) {
                            return <img src={load}/>
                        } else {
                            return <Button fullWidth={true} onClick={() => selectFile()} variant="contained" color="primary">
                                {t('SELECT FILE')}
                                <CloudUploadIcon style={{paddingLeft: 10, fontSize: 26}}/>
                            </Button>
                        }
                    }
                }
                return <>
                    <Grid item xs={12} sm={4}>
                        <Segment>
                            <FileItem/>
                        </Segment>
                    </Grid>
                </>
            } else {
                return <></>
            }
        }

        function newFile() {
            setFiles({})
            setLoadFile(false)
            setOdeLoad(false)
            setLoadDownload(false)
            setOdeLoad(false)
            setCredits([])
            setEcuId(null)
            setEcuId(null)
            setActiveStep(0)
            history.push('/ecu/file/form')
        }

        const Step_1 = () => {
            if (activeStep == 0) {
                return <>
                    <SelectBrands/>
                    <EcuListView/>
                    <SelectFileView/>
                </>
            } else {
                return <></>
            }

        }

        function payments() {
            switch (credits.length) {
                case 0:
                    return 0
                    break
                case 1:
                    if (credits.includes(1)) {
                        return 10
                    } else {
                        return 7
                    }
                    break
                case 2:
                    return 10
                    break
                case 3:
                    return 15
                    break
                case 4:
                    return 15
                    break
                case 5:
                    return 15
                    break
                default:
                    return 15
                    break
            }
            return 0
        }

        function goLink(url) {
            window.open(url)
        }

        const session = useCESLogin()
        const UserCredit = () => {
            if (user.credit < payments()) {
                return <span target="_blank" onClick={() => goLink(`https://carecusoft.com/add-credit?token=${user.var_autologon}&session=${session}&url=add-credit`)} style={{color: "blue", fontSize: 11}}>{t('BUY CREDITS')}</span>
            } else
                return <span style={{color: "blue", fontSize: 11}}>{t('YOUR ACCOUNT CREDITS')} {user.credit}</span>
        }

        function paid(payments) {
            if (payments == 0) {
                return toast.warn('SELECT A PROCESS');
            }
            setOdeLoad(true)
            instance
                .post('/api/ecu/credit/paid', {tuning_types: credits, payments: payments, file_id: files.id})
                .then(function (res) {
                    if (res.data.err == 2) {
                        return toast.warn(res.data.msg);
                    }
                    setFiles({...files, ['paid']: res.data.paid, ['edit_file_id']: res.data.edit_file_id})
                    setOdeLoad(false)
                    setActiveStep(2);
                    setUser({...user, ['credit']: user.credit - payments})
                })
        }

        const OdeButton = () => {
            if (files.paid === 1) {
                return <>
                    {t('SUCCESS PAY')}
                    <Divider/><br/>
                    <ButtonDownload/>
                </>
            } else {
                if (odeLoad) {
                    return <img src={load} alt=""/>
                } else {
                    return <Button onClick={() => paid(payments())} fullWidth={true} color={"primary"} size={"large"} variant="contained" className={classes.button}>
                        {payments()} {t('CREDIT PAY')}
                    </Button>
                }
            }

        }
        const Step_2 = () => {
            if (activeStep == 1) {
                if (files.status == true) {
                    return <>
                        <Grid alignItems={"center"} alignContent={"center"} justify={"center"} item xs={12} sm={4}>
                            <Segment>
                                <Button disabled={activeStep === 0} variant={"contained"} color={"secondary"} onClick={handleBack}>
                                    <ArrowBackIosIcon/> {t('BACK')}
                                </Button>
                                <span className={classes.segmentTitle}>{t('FILE UPLOADED SUCCESS')}</span>
                                <Divider/>
                                <br/>
                                <List>
                                    <ListItem role={undefined} dense>
                                        <ListItemIcon><CloudDoneIcon/></ListItemIcon>
                                        <ListItemText primary={files.name}/>
                                    </ListItem>
                                    <ListItem role={undefined} dense>
                                        <ListItemIcon><DiscFullIcon/></ListItemIcon>
                                        <ListItemText primary={files.size + ' MB'}/>
                                    </ListItem>
                                </List>
                            </Segment>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Segment>
                                <span className={classes.segmentTitle}>{t('SELECT TRANSACTIONS')}</span>
                                <Divider/>
                                <br/>
                                <TuningTypes/>
                            </Segment>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Segment>
                                <span className={classes.segmentTitle}>{t('AMOUNT TO SPEND')} <UserCredit/></span>
                                <Divider/>
                                <br/>
                                <OdeButton/>
                            </Segment>
                        </Grid>
                    </>;
                } else {
                    return <>
                        <Grid item xs={12} sm={4}>
                            <Segment>
                                <Button disabled={activeStep === 0} color={"secondary"} onClick={handleBack}>
                                    <ArrowBackIosIcon/> {t('BACK')} {t('FILE UPLOADED SUCCESS')}
                                </Button>
                                <List>
                                    <ListItem role={undefined} dense>
                                        <ListItemText primary={`Line item `}/>
                                        <ListItemSecondaryAction>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Segment>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Segment>
                                {t('THIS FILE DOES NOT CURRENTLY HAVE ACTION')}
                            </Segment>
                        </Grid>
                    </>;
                }
            } else {
                return <></>
            }

        }

        function getFileDownload(file_id) {
            instance
                .get(`/api/ecu/file/download/link/${file_id}`)
                .then(function (res) {
                    window.open(`https://api.carecusoft.com/api/ecu/file/download?file_name=${res.data}`);
                })

        }

        //href={`https://api.carecusoft.com/api/ecu/file/download/edit/${user.remember_token}/${files.edit_file_id}`}
        const ButtonDownload = () => {
            if (loadDownload) {
                return <img src={load}/>
            } else {
                return <Button variant="contained" onClick={() => getFileDownload(files.edit_file_id)}
                               color={"primary"} size={"large"} fullWidth={true}>{t('DOWNLOAD NOW')}</Button>
            }
        }
        const Step_3 = () => {
            if (activeStep == 2) {
                return <>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Segment>
                                <Button variant={"contained"} color={"secondary"} onClick={handleBack}>
                                    <ArrowBackIosIcon/> {t('BACK')}
                                </Button>
                                <h4> {t('Your file is ready')} </h4>
                                <Divider/>
                                <br/>
                                <ButtonDownload/>
                            </Segment>
                        </Grid>
                    </Grid>
                </>;
            } else {
                return <></>
            }
        }
        return (<>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector/>}>
                            {steps.map(label => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </Grid>
                <Grid spacing={2} container>
                    <Grid container item sm={12} xs={12} alignItems={"center"} alignContent={"center"} justify={"center"}>
                        <Button variant={"contained"} size={"large"} color={"secondary"} onClick={() => newFile()}>{t('NEW FILE REQUEST')}</Button>
                    </Grid>
                    <Step_1/>
                    <Step_2/>
                    <Step_3/>
                    <input id="ecuFile" style={{display: "none"}} type='file'/>

                </Grid>
            </>
        );
    }
    return (
        <Main Context={Context} pageTitle={t('Online File Service')}/>

    )
}
