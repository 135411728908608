import React, {useContext, useState} from 'react';
import {FormControlLabel} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {CreditsContext, FileContext, SelectTypesContext} from "../stores/File";

const TuningTypes = () => {
    const [selectTypes, setSelectTypes] = useContext(SelectTypesContext);
    const [files,] = useContext(FileContext)
    const [credits, setCredits] = useContext(CreditsContext)
    const handleSelectType = (id, value) => event => {
        const checked = event.target.checked;
        setSelectTypes({...selectTypes, [id]: checked});
        if (credits.includes(id) && checked === false) {
            const filtered = credits.filter(x => x !== id)
            return setCredits(filtered)
        }
        if (checked === true) {
            credits.push(id)
        }
        return setCredits(credits);
    };
    try {
        return files.ttitems.map(item => (
            <span key={item.id}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleSelectType(item.type.id, item.type.credit)}
                                    color="primary"
                                    checked={selectTypes[item.type.id]}
                                />
                            }
                            label={item.type.name}
                        />
                    </span>
        ))

    } catch (e) {
        return <></>
    }
}

export default TuningTypes;
