import React, {useEffect, useState} from 'react';
import Main from "./Main";
import {Grid, Segment,} from 'semantic-ui-react'
import instance from "../apis";
import MaterialTable from 'material-table';
import {toast} from "react-toastify";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import Button from '@material-ui/core/Button';
import {useTranslation} from "react-i18next";

const NewEcuPage = (props) => {
    const {t, i18n} = useTranslation();
    const history = props.history;
    const [ecus, setEcus] = useState([]);
    const newEcuPage = () => {
        history.push('/ecu/admin/ecus')
    }
    useEffect(() => {
        instance
            .get('/api/ecu/all')
            .then(function (res) {
                const getData = [];
                res.data.map(item => {
                    getData.push({
                        brand: item.brand,
                        model: item.model,
                        processor: item.processor,
                        id: item.id,
                    })
                })
                setEcus(getData);
            })

    }, [])
    const Context = () => {
        const [ecu, setEcu] = useState({
            brand: '',
            model: '',
            processor: ''
        })
        const EcuList = () => {
            const [state, setState] = useState({
                columns: [
                    {title: 'id', field: 'id', readonly: true, hidden: true},
                    {title: 'Brand', field: 'brand'},
                    {title: 'Model', field: 'model'},
                    {title: 'Processor', field: 'processor'},

                ],
                data: ecus,
            });

            const tableTitle = () => {
                return (<>
                    <Button variant="contained" onClick={newEcuPage} size="small" color="secondary">
                        <ArrowBackIosOutlinedIcon/>
                    </Button> {t('ECU LIST')}
                </>)
            }
            const toastFunc = (res) => {
                if (res.status == 200) {
                    toast.success(res.data.msg)
                } else {
                    toast.warn(t('Not Add'))
                }
            }
            return (<>
                    <MaterialTable
                        title={tableTitle()}
                        columns={state.columns}
                        data={state.data}
                        editable={{
                            onRowAdd: newData =>
                                new Promise(resolve => {
                                    resolve();
                                    const data = [...state.data];
                                    data.push(newData);
                                    setState({...state, data});
                                    instance
                                        .post('/api/ecu/new', {
                                            brand: newData.brand,
                                            model: newData.model,
                                            processor: newData.processor
                                        })
                                        .then(function (res) {
                                            toastFunc(res)
                                        })
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    resolve();
                                    const data = [...state.data];
                                    data[data.indexOf(oldData)] = newData;
                                    setState({...state, data});
                                    instance
                                        .post('/api/ecu/new', {
                                            brand: newData.brand,
                                            model: newData.model,
                                            processor: newData.processor,
                                            id: oldData.id
                                        })
                                        .then(function (res) {
                                            toastFunc(res)

                                        })

                                }),
                            onRowDelete: oldData =>
                                new Promise(resolve => {

                                    resolve();
                                    const data = [...state.data];
                                    data.splice(data.indexOf(oldData), 1);
                                    setState({...state, data});
                                    instance
                                        .delete(`/api/ecu/new?id=${oldData.id}`)
                                        .then(function (res) {
                                            toastFunc(res)
                                        })
                                }),
                        }}
                    />
                </>
            );
        }
        return (
            <>
                <Grid>
                    <Grid.Column>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <EcuList/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>

                </Grid>

            </>
        );
    }
    return <Main Context={Context}/>
}

export default NewEcuPage;
