import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import instance from '../apis';
import Main from './Main';
import {Segment, Dropdown} from 'semantic-ui-react'
import load from '../load.gif';
import TextField from '@material-ui/core/TextField';
import useForm from "../hooks/useForm";
import {toast,} from "react-toastify";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {red} from "@material-ui/core/colors";
import {InputContext} from "../Store";
import {InputLabel, FormControl, Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(({
    items: {
        borderBottom: "1px solid #e3e7f1"
    },

}));
export default function AdminTuningTypes(props) {
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState()
    const history = props.history;
    const classes = useStyles();

    const newPage = () => {
        history.push('/admin/tuning/type/new')
    }
    const [values, setValues] = useState({
        ecu_id: '',
        type_id: '',
        brand_id: '',
        brands: [],
        selectBrands: []
    });
    const [ecuList, setEcuList] = useState([]);
    const [tuningTypeList, setTuningTypeList] = useState([]);
    const [tuningTypeDataList, setTuningTypeDataList] = useState([]);
    const [loadEcu, setLoadEcu] = useState(false)
    const [loadType, setLoadType] = useState(false)
    const [loadData, setLoadData] = useState(false)
    const [, setEditItem] = useState(false)
    const [, setInputs] = useContext(InputContext)

    function newForms() {
        setInputs({search_type: 61, variable_type: 61})
    }

    useEffect(() => {
        instance
            .get('/api/ecu/brands')
            .then(function (res) {
                const getData = [];
                res.data.map(item => {
                    getData.push({
                        key: item.id,
                        value: item.id,
                        text: item.var_title
                    })
                })
                setValues(values => ({
                    ...values,
                    ['brands']: res.data,
                    ['selectBrands']: getData
                }));
            })
    }, [])

    const Context = () => {
        const handleChanges = (e, {name, value}) => {
            setValues(values => ({...values, [name]: value, ["ecu_id"]: null, ["type_id"]: null}))
            setLoadEcu(true)
            instance
                .get(`/api/ecu/eculist/${value}`)
                .then(function (res) {
                    const getData = [];
                    res.data.map(item => {
                        getData.push({
                            key: item.id,
                            value: item.id,
                            text: item.brand + " " + item.model + " " + item.processor,
                        })
                    })
                    setEcuList(getData)
                    setTuningTypeList([])
                    setLoadEcu(false)

                })
        }

        function handleEcuSelect(e, {name, value}) {
            setValues(values => ({...values, [name]: value, ['type_id']: null}))
            setLoadType(true)
            instance
                .get(`/api/ecu/tuning/types/all`)
                .then(function (res) {
                    const getData = [];
                    res.data.map(item => {
                        getData.push({
                            key: item.id,
                            value: item.id,
                            text: item.name,
                        })
                    })
                    setTuningTypeList(getData)
                    setLoadType(false)
                })
        }

        function handleTuningTypeSelect(e, {name, value}) {
            setEditItem(true)
            setValues(values => ({...values, [name]: value}))
            setLoadData(true)
            instance
                .get(`/api/ecu/tuning/type/items/${values.brand_id}/${values.ecu_id}/${value}`)
                .then(function (res) {
                    setTuningTypeDataList(res.data)
                    setLoadData(false)
                    setEditItem(false)
                })
        }

        const EcuItemsView = () => {
            const [inputs, setInputs] = useContext(InputContext)
            const {handleInputChange, handleSubmit} = useForm(sendTypeItemSubmit);
            if (!values.type_id) {
                return <></>
            }
            const itemEdit = id => {
                setEditItem(true)
                instance
                    .get(`/api/ecu/tuning/type/item/${id}`)
                    .then(function (res) {
                        setInputs(res.data)
                        setEditItem(false)
                    })
            }


            function sendTypeItemSubmit() {
                setEditItem(true)
                instance
                    .post('/api/ecu/tuning/type/item/new', {
                        id: inputs.id,
                        search: inputs.search,
                        variable: inputs.variable,
                        key: inputs.key,
                        key_search: inputs.key_search,
                        search_type: inputs.search_type,
                        variable_type: inputs.variable_type,
                        brand_id: values.brand_id,
                        ecu_id: values.ecu_id,
                        type_id: values.type_id
                    })
                    .then(function (res) {
                        if (res.status == 200) {
                            if (inputs.id) {
                                toast.success(t('Update Success'))
                            } else {
                                toast.success(t('Create Success'))
                            }
                            setTuningTypeDataList(res.data)
                            document.getElementById("searchVariableId").reset();
                            setEditItem(false)
                        } else {
                            toast.warn(t('Somting wrong, please try later'))
                        }

                    }).catch(function () {
                        toast.warn(t('Somting wrong, please try later'))
                    }
                )
            }

            const EcuItemsDataView = () => {

                const itemDelete = id => {
                    if (window.confirm(t('Are you sure you want to delete?')) == true) {
                        instance
                            .delete(`/api/ecu/tuning/type/item/${id}`)
                            .then(function (res) {
                                const data = tuningTypeDataList.filter(x => x.id != id)
                                setTuningTypeDataList(data)
                                if (res.status == 200) {
                                    toast.success(t('Delete Success'))
                                } else {
                                    toast.warn(t('Something is wrong'))
                                }
                            })
                    }
                }


                if (tuningTypeDataList) {
                    return tuningTypeDataList.map(item =>
                        (
                            <Grid key={item.id} item container>
                                <Grid xs={1} sm={1} item justify={"center"}>
                                    <EditIcon onClick={() => itemEdit(item.id)} style={{cursor: "pointer"}}/>
                                </Grid>
                                <Grid xs={1} sm={1} item>
                                    <DeleteIcon onClick={() => itemDelete(item.id)} style={{color: "red", cursor: "pointer"}}/>
                                </Grid>
                                <Grid xs={10} sm={5} item>
                                    <div className={classes.items}>{item.search}</div>
                                </Grid>
                                <Grid xs={12} sm={5} item>
                                    <div className={classes.items} style={{color: "red"}}>{item.variable}</div>
                                </Grid>
                            </Grid>
                        )
                    )
                }
            }

            const SelectSearchType = () => (
                <FormControl>
                    <InputLabel id="search_type">{t('Search Type')}</InputLabel>
                    <Select
                        labelId="search_type"
                        id="search_type"
                        value={inputs.search_type}
                        onChange={handleInputChange}
                        name="search_type"
                    >
                        <MenuItem value={8}>8 Bit</MenuItem>
                        <MenuItem value={16}>16 LoHi</MenuItem>
                        <MenuItem value={61}>16 HiLo</MenuItem>
                    </Select>
                </FormControl>
            )
            const SelectVariableType = () => (
                <FormControl>
                    <InputLabel id="variable_type">{t('Variable Type')}</InputLabel>
                    <Select
                        labelId="variable_type"
                        id="variable_type"
                        value={inputs.variable_type}
                        onChange={handleInputChange}
                        name="variable_type"
                    >
                        <MenuItem value={8}>8 Bit</MenuItem>
                        <MenuItem value={16}>16 LoHi</MenuItem>
                        <MenuItem value={61}>16 HiLo</MenuItem>
                    </Select>
                </FormControl>
            )

            if (loadData) {
                return <Grid item>
                    <Segment><img src={load} alt=""/></Segment>
                </Grid>
            } else {
                return (
                    <>
                        <Grid spacing={2} container>
                            <Grid container item sm={12} xs={12} alignItems={"center"} alignContent={"center"} justify={"center"}>
                                <Button variant={"contained"} size={"large"} color={"secondary"} onClick={() => newForms()}>{t('ADD NEW VARIABLE')}</Button>
                            </Grid>
                        </Grid>
                        <form id={"searchVariableId"} onSubmit={handleSubmit}>
                            <Grid justify="center"
                                  alignItems="center" spacing={2} container>
                                <Grid xs={12} sm={5} item>
                                    <TextField
                                        required
                                        fullWidth={true}
                                        autoFocus={true}
                                        id="search"
                                        label={t('Changed Variable')}
                                        type="text"
                                        name="search"
                                        margin="normal"
                                        variant="outlined"
                                        onChange={handleInputChange}
                                        defaultValue={inputs.search}
                                    />
                                </Grid>
                                <Grid xs={12} sm={5} item>
                                    <TextField
                                        required
                                        fullWidth={true}
                                        id="variable"
                                        label={t('New Variable')}
                                        type="text"
                                        name="variable"
                                        margin="normal"
                                        variant="outlined"
                                        onChange={handleInputChange}
                                        defaultValue={inputs.variable}

                                    />
                                </Grid>
                                <Grid xs={12} sm={2} item>
                                    <SelectSearchType/>
                                </Grid>
                                <Grid xs={12} sm={5} item>
                                    <TextField
                                        fullWidth={true}
                                        id="key_search"
                                        label={t('Search In ECU Files (if empty this, search Changed Variable)')}
                                        type="text"
                                        name="key_search"
                                        margin="normal"
                                        variant="outlined"
                                        onChange={handleInputChange}
                                        defaultValue={inputs.key_search}
                                    />
                                </Grid>
                                <Grid xs={12} sm={5} item>
                                    <TextField
                                        fullWidth={true}
                                        id="key"
                                        label={t('Keywords for Search')}
                                        type="text"
                                        name="key"
                                        margin="normal"
                                        variant="outlined"
                                        onChange={handleInputChange}
                                        defaultValue={inputs.key}
                                    />
                                </Grid>

                                <Grid xs={12} sm={2} item>
                                    <SelectVariableType/>
                                </Grid>
                                <Grid xs={12} sm={12} item>
                                    <Button type="submit" variant="contained" size="large" fullWidth={true} color="primary">
                                        {t('SAVE')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid container spacing={2} alignItems={"center"} alignContent={"center"}>
                            <Grid xs={12} sm={12} item style={{textAlign: "center"}}>
                                <hr/>
                                <h4>{t('ADDED VALUES')}</h4>
                            </Grid>
                            <EcuItemsDataView/>
                        </Grid>
                    </>
                )
            }
        }
        const SelectBrands = () => {
            return <>
                <Grid item xs={12} sm={4} >
                    <Segment>
                        <Dropdown
                            search
                            selection
                            name="brand_id"
                            options={values.selectBrands}
                            onChange={handleChanges}
                            defaultValue={values.brand_id}
                            placeholder={t('Select Brand')}
                        />
                    </Segment>
                </Grid>
            </>
        }
        const EcuListView = () => {
            if (values.brand_id) {
                if (loadEcu) {
                    return <Grid item xs={12} sm={4}>
                        <Segment><img src={load} alt=""/></Segment>
                    </Grid>
                }
                return <>
                    <Grid item sm={4} xs={12}>
                        <Segment>
                            <Dropdown
                                search
                                selection
                                name="ecu_id"
                                options={ecuList}
                                placeholder={t('Select ECU')}
                                onChange={handleEcuSelect}
                                value={values.ecu_id}
                            />
                        </Segment>
                    </Grid>
                </>
            } else {
                return <></>
            }
        }
        const TuningTypesView = () => {
            if (values.ecu_id) {
                if (loadType) {
                    return <Grid item>
                        <Segment><img src={load} alt=""/></Segment>
                    </Grid>
                }
                return <>
                    <Grid sm={4} xs={12} >
                        <Segment>
                            <Dropdown
                                search
                                selection
                                name="type_id"
                                options={tuningTypeList}
                                placeholder={t('Select Tuning Types')}
                                onChange={handleTuningTypeSelect}
                                value={values.type_id}
                            />
                        </Segment>
                    </Grid>
                </>
            } else {
                return <></>
            }
        }


        return (<>

                <Grid container spacing={2}>
                    <SelectBrands/>
                    <EcuListView/>
                    <TuningTypesView/>
                </Grid>
                <EcuItemsView/>
            </>
        );
    }
    return (
        <Main Context={Context} pageTitle={t('Tuning Values Home Page')}/>
    )
}
