import React, {useEffect, useState} from 'react';
import Main from "./Main";
import {Grid, Segment,} from 'semantic-ui-react'
import instance from "../apis";
import MaterialTable from 'material-table';
import {toast} from "react-toastify";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import Button from '@material-ui/core/Button';
import load from '../load.gif'
import {useTranslation} from "react-i18next";

const NewTuningType = (props) => {
    const {t, i18n} = useTranslation();
    const history = props.history;
    const [getDatas, setGetDatas] = useState([]);
    const [loading, setLoading] = useState(false);
    const goBack = () => {
        history.push('/admin/tuning/types')
    }
    useEffect(() => {
        setLoading(true)
        instance
            .get('/api/ecu/tuning/types/all')
            .then(function (res) {
                const getData = [];
                res.data.map(item => {
                    getData.push({
                        name: item.name,
                        id: item.id,
                    })
                })
                setGetDatas(getData);
                setLoading(false)
            })

    }, [])
    const Context = () => {
        const ItemList = () => {
            const [state, setState] = useState({
                columns: [
                    {title: 'ID', field: 'id', readonly: true, hidden: true},
                    {title: 'Name', field: 'name'},
                ],
                data: getDatas,
            });

            const tableTitle = () => {
                return (<>
                    <Button variant="contained" onClick={goBack} size="small" color="secondary">
                        <ArrowBackIosOutlinedIcon/>
                    </Button> {t('TUNING TYPES')}
                </>)
            }
            const toastFunc = (res) => {
                if (res.status == 200) {
                    toast.success(res.data.msg)
                } else {
                    toast.warn(t('Not Add'))
                }
            }
            return (<>
                    <MaterialTable
                        title={tableTitle()}
                        columns={state.columns}
                        data={state.data}
                        editable={{
                            onRowAdd: newData =>
                                new Promise(resolve => {
                                    resolve();
                                    const data = [...state.data];
                                    data.push(newData);
                                    setState({...state, data});
                                    instance
                                        .post('/api/ecu/tuning/type/new', {
                                            name: newData.name,
                                        })
                                        .then(function (res) {
                                            toastFunc(res)
                                        })
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    resolve();
                                    const data = [...state.data];
                                    data[data.indexOf(oldData)] = newData;
                                    setState({...state, data});
                                    instance
                                        .post('/api/ecu/tuning/type/new', {
                                            name: newData.name,
                                            id: oldData.id
                                        })
                                        .then(function (res) {
                                            toastFunc(res)
                                        })

                                }),
                            onRowDelete: oldData =>
                                new Promise(resolve => {
                                    resolve();
                                    const data = [...state.data];
                                    data.splice(data.indexOf(oldData), 1);
                                    setState({...state, data});
                                    instance
                                        .delete(`/api/ecu/tuning/type/new?id=${oldData.id}`)
                                        .then(function (res) {
                                            toastFunc(res)
                                        })
                                }),
                        }}
                    />
                </>
            );
        }
        return (
            <>
                <Grid>
                    <Grid.Column>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <ItemList/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>

                </Grid>

            </>
        );
    }
    return <Main Context={Context}/>
}

export default NewTuningType;
