import React, {useEffect, useState} from 'react';
import Main from "./Main";
import {useTranslation} from "react-i18next";
import useCESLogin from "../hooks/useCESLogin";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import instance from '../apis';
import Grid from '@material-ui/core/Grid';
import {Paper} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));
const Profile = () => {
    const {t, i18n} = useTranslation();
    const session = useCESLogin()
    const [user, setUser] = useState({})
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        instance
            .post('/api/ecu/user')
            .then(function (res) {
                setUser(res.data)
            })
    }, [])

    function goLink(url) {
        window.open(url)
    }

    const Context = () => {
        return <>
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            C
                        </Avatar>
                    }
                    title={t('Your Credits')}
                    subheader=""
                />

                <CardContent>
                    <Typography variant="body2" color="textSecondary" style={{textAlign: "center"}} component="p">
                        <span style={{fontSize: 36}}>{user.credit}</span>
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton aria-label="add to credit">
                        <AddCircleIcon onClick={() => goLink(`https://carecusoft.com/add-credit?token=${user.var_autologon}&session=${session}&url=add-credit`)}/>
                    </IconButton>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon/>
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography paragraph>
                            {t('credit_car_title')}
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        </>
    }
    return (
        <Main Context={Context} pageTitle={t('My Profile')}/>
    );
}

export default Profile;
