import axios from 'axios';

const instance = axios.create({
    baseURL: "https://api.carecusoft.com",
});
if (localStorage.getItem('token')) {
    instance.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
}

export default instance
