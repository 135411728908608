import React, {useContext, useState} from 'react';
import {InputContext} from "../Store";

const useForm = (calback) => {
    const [inputs, setInputs] = useContext(InputContext);
    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        calback()
    }
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }
    return {
        handleSubmit,
        handleInputChange,
        inputs
    };
}

export default useForm;
