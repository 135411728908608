import React, {useState, createContext} from 'react';

export const UserContext = createContext([]);
export const InputContext = createContext([]);
export const Store = props => {
    const [user, setUser] = useState({})
    const [inputs, setInputs] = useState({search_type: 61, variable_type: 61})
    return (
        <UserContext.Provider value={[user, setUser]}>
            <InputContext.Provider value={[inputs, setInputs]}>
                {props.children}
            </InputContext.Provider>
        </UserContext.Provider>
    );
}

