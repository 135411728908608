import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Segment} from "semantic-ui-react";
import MemoryIcon from '@material-ui/icons/Memory';
import {Link} from 'react-router-dom';
import Main from "./Main";
import {useTranslation} from "react-i18next";

const HomePage = () => {
    const {t, i18n} = useTranslation();

    const Context = () => {
        return (
            <Grid container>
                <Grid item xs={4} sm={2} container alignContent={"center"} alignItems={"center"} justify={"center"}>
                    <Link to={'/ecu/file/form'}> <MemoryIcon style={{fontSize:60}}/></Link>
                    {t('Online Processor')}
                </Grid>
            </Grid>
        );
    }
    return <Main Context={Context}/>
}

export default HomePage;
