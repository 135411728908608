import React from 'react';
import {Switch, Route} from "react-router-dom";
import FileForm from "./FileForm";
import AdminEcus from "./AdminEcus";
import NewEcuPage from "./NewEcuPage";
import AdminTuningTypes from "./AdminTuningTypes";
import NewTuningType from "./NewTuningType";
import {File} from "../stores/File";
import MyFiles from "./MyFiles";
import Profile from "./Profile";

const Home = (props) => {
    const checkLogin = localStorage.getItem('login')
    const roles = localStorage.getItem('roles')
    const history = props.history;
    if (checkLogin !== "true") {
        history.push('/login')
    }
    const AdminRoute = () => {
        if (roles) {
            if (roles.includes('admin')) {
                return (
                    <>
                        <Route path={'/ecu/admin/tuning/types/'} component={AdminTuningTypes}/>
                        <Route path={'/ecu/admin/tuning/type/new/'} component={NewTuningType}/>
                        <Route path={'/ecu/admin/ecu/new/'} component={NewEcuPage}/>
                        <Route path={'/ecu/admin/ecus/'} component={AdminEcus}/>
                        <Route path={'/ecu/admin/ecu/new/:id/'} component={NewEcuPage}/>
                    </>
                )
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }
    }
    return (

        <Switch>
            <File>
                <Route path={'/ecu/file/form'} component={FileForm}/>
                <Route path={'/ecu/my/files'} component={MyFiles}/>
                <Route path={'/ecu/profile'} component={Profile}/>
                <AdminRoute/>
            </File>
        </Switch>
    );
}

export default Home;
