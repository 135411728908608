import React, {Suspense} from 'react';
import './App.css';
import {HashRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages/Home';
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import HomePage from "./pages/HomePage";
import './i18n';

function App() {
    return (
        <Suspense fallback="loading">
            <Router>
                <Switch>
                    <Route path={'/login'} component={Login}/>
                    <Route path={'/logout'} component={Logout}/>
                    <Route path={'/ecu'} component={Home}/>
                    <Route path={'/'} component={HomePage}/>
                </Switch>
            </Router>
        </Suspense>
    );
}

export default App;
