import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import useForm from '../hooks/useForm';
import instance from "../apis";
import {useTranslation} from "react-i18next";
import {toast, ToastContainer} from 'react-toastify';

const Login = (props) => {
    const {t} = useTranslation();
    const checkLogin = localStorage.getItem('login');
    const history = props.history;
    if (checkLogin == "true") {
        history.push('/ecu/file/form')
    }
    const loginPost = () => {
        instance
            .post('/oauth/token', {
                password: inputs.password,
                username: inputs.email,
                client_id: 3,
                client_secret: "2bBXwR19O0m3Thu1yoFDd4ZuDnqpYVbS3qv40Y8n",
                grant_type: "password"

            })
            .then(function (res) {
                if (res.data.access_token) {
                    localStorage.setItem('token', res.data.access_token);
                    localStorage.setItem('login', true);
                    instance
                        .get('/api/getUser', {
                            headers: {'Authorization': "Bearer " + res.data.access_token}
                        })
                        .then(function (res) {
                                const roles = [];
                                res.data.roles.map(role => {
                                    roles.push(role.name)
                                })
                                localStorage.setItem('roles', roles);
                                localStorage.setItem('user', res.data);
                                window.location.reload();
                            }
                        )
                } else {
                    toast.warn(t('loginError'))
                }

            })
            .catch(function () {
                    toast.error(t('loginError'))
                }
            )

    }
    const {inputs, handleInputChange, handleSubmit} = useForm(loginPost);

    function MadeWithLove() {
        return (
            <Typography variant="body2" color="textSecondary" align="center">
                {t('7/24 Online File Service')}
            </Typography>
        );
    }

    const useStyles = makeStyles(theme => ({
        root: {
            height: '100vh',
        },
        image: {
            backgroundImage: 'url(https://source.unsplash.com/collection/8262943)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }));

    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t('Email Address')}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={inputs.email}
                            onChange={handleInputChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t('Password')}
                            type="password"
                            id="password"
                            value={inputs.password}
                            onChange={handleInputChange}
                            autoComplete="current-password"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="https://www.carecusoft.com/lost-password?url=online.carecusoft.com" variant="body2">
                                    {t("Forgot your password")}?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="https://carecusoft.com/register?url=online.carecusoft.com" variant="body2">
                                    {t("Don't have an account? Sign Up")}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>
                            <MadeWithLove/>
                        </Box>
                    </form>
                </div>
            </Grid>
            <ToastContainer/>

        </Grid>
    );

}

export default Login;
