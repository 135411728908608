import React, {useEffect, useState} from 'react';
import instance from '../apis';
import Main from "./Main";
import Grid from '@material-ui/core/Grid';
import {Paper} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    donwloadIcon: {
        cursor: "pointer",
        fontSize: 26,
        float: 'right',
        "&:hover": {
            color: "#315b96"
        },
    },
    editFile: {
        backgroundColor: "#e3e7f1"
    }
}));
const MyFiles = () => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const [myFiles, setMyFiles] = useState([])
    useEffect(() => {
        instance
            .get('/api/ecu/my/files')
            .then(function (res) {
                setMyFiles(res.data)
            })

    }, [])

    function getFileDownload(file_id) {
        instance
            .get(`/api/ecu/file/download/link/${file_id}`)
            .then(function (res) {
                window.open(`https://api.carecusoft.com/api/ecu/file/download?file_name=${res.data}`);
            })

    }

    const EditFile = ({fileData}) => {
        if (fileData.file) {
            return <Paper className={`${classes.paper} ${classes.editFile}`}>{fileData.file.org_name} <CloudDownloadIcon className={classes.donwloadIcon} onClick={() => getFileDownload(fileData.file.id)}/>
            </Paper>
        } else {
            return <></>
        }
    }
    const FileRow = () => {
        return myFiles.map(file => <Grid key={file.id} spacing={2} container>
            <Grid item xs={12} sm={2}>
                <Paper className={classes.paper}>{file.brand.var_title} </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Paper className={classes.paper}>{`${file.ecu.brand} ${file.ecu.model} ${file.ecu.processor}`} </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Paper className={classes.paper}>{file.org_name}
                    <CloudDownloadIcon className={classes.donwloadIcon} onClick={() => getFileDownload(file.id)}/>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
                <EditFile fileData={file}/>
            </Grid>
        </Grid>)
    }
    const Context = () => (
        <FileRow/>
    );
    return <Main Context={Context} pageTitle={t('MyFiles')}/>
}

export default MyFiles;
