import React, {useState, createContext} from 'react';

export const FileContext = createContext({});
export const CreditsContext = createContext([]);
export const StepContext = createContext([]);
export const SelectTypesContext = createContext([]);
export const File = props => {
    const [file, setFile] = useState({})
    const [step, setStep] = useState(0)
    const [credits, setCredits] = useState([])
    const [selectTypes, setselectTypes] = useState([])
    return (
        <StepContext.Provider value={[step, setStep]}>
            <FileContext.Provider value={[file, setFile]}>
                <CreditsContext.Provider value={[credits, setCredits]}>
                    <SelectTypesContext.Provider value={[selectTypes, setselectTypes]}>
                        {props.children}
                    </SelectTypesContext.Provider>
                </CreditsContext.Provider>
            </FileContext.Provider>
        </StepContext.Provider>
    );
}
