import _ from 'lodash'
import React, {useEffect, useState} from 'react';
import Main from "./Main";
import {Grid, Segment, Dropdown, Table, Checkbox, Input} from 'semantic-ui-react'
import instance from "../apis";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {toast} from 'react-toastify';
import GridColumn from "semantic-ui-react/dist/commonjs/collections/Grid/GridColumn";
import GridRow from "semantic-ui-react/dist/commonjs/collections/Grid/GridRow";
import load from "../load.gif";
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {useTranslation} from "react-i18next";

const AdminEcus = (props) => {
    const {t, i18n} = useTranslation();
    const [ecus, setEcus] = useState([])
    const [ecu_id, setecu_id] = useState('')
    const [checkBrands, setCheckBrands] = useState([])
    const [brands, setBrands] = useState([])
    const [loading, setLoading] = useState(false);
    const [identifications, setIdentifications] = useState([])

    const history = props.history;
    useEffect(() => {
        setLoading(true)
        instance
            .get('/api/ecu/all')
            .then(function (res) {
                const getData = [];
                res.data.map(item => {
                    getData.push({
                        key: item.id,
                        value: item.id,
                        text: `${item.brand} ${item.model} ${item.processor}`,
                    })
                })
                setEcus(getData);
                setLoading(false)
            })
        instance
            .get('/api/ecu/brands/all')
            .then(function (res) {
                setBrands(res.data);
            })

    }, [])
    const handleChanges = (e, {name, value}) => {
        setecu_id(value)
        instance
            .get(`/api/ecu/brand/ids?ecu_id=${value}`)
            .then(function (res) {
                setCheckBrands(res.data.brand_ids)
                setIdentifications(res.data.identifications)
            })
    }

    const BrandsList = () => {

        const [firstBrands, setFirstBrands] = useState(brands)
        const [loadingKw, setloadingKw] = useState(false)
        const [kw, setKw] = useState('');

        const handleSearchChange = (e, {value}) => {
            setKw(value.toLowerCase())
            setloadingKw('loading')
            const filteredPoets = brands.filter((brand) => {
                let poetName = brand.var_title.toLowerCase()
                return poetName.indexOf(
                    value.toLowerCase()) !== -1
            })
            setFirstBrands(filteredPoets)
            setloadingKw('')
        }
        const [brandIds, setBrandIds] = useState(checkBrands)

        const RowView = () => {
            const [identies, setIdenties] = useState(identifications)

            const selectBrand = (id) => {
                instance
                    .get(`/api/ecu/brand/select/${ecu_id}/${id}`)
                    .then(
                        function (res) {
                            if (res.status == 200) {
                                toast.success(res.data.msg)
                                brandIds.push(Number(res.data))
                                setBrandIds(brandIds)
                            } else {
                                const index = brandIds.indexOf(id)
                                if (index !== -1) {
                                    brandIds.splice(index, 1);
                                    setBrandIds(brandIds)
                                }
                                toast.warn(t('Somting wrong, please try later'))
                            }
                        }
                    )
            }

            const [viewInput, setViewInput] = useState([])

            function IndetyInputView(identification, brand_id, index) {
                const getValue = identy(identification, brand_id, index)
                const [value, setValue] = useState(getValue)

                function handleInputChange(e) {
                    setValue(e.target.value)
                }

                function inputSave(brand_id) {
                    instance
                        .post('/api/ecu/identification', {
                            value: value,
                            brand_id: brand_id,
                            ecu_id: ecu_id
                        })
                        .then(function (res) {
                                setIdenties(res.data.identifications)
                                setViewInput([])
                                if (res.data.add == 1) {
                                    setBrandIds(res.data.brand_ids)
                                }
                                toast.success(t('Update Success'))
                            }
                        )
                }


                function identy(identy, brand_id) {
                    try {
                        return identy[brand_id];

                    } catch (e) {
                        return '';

                    }
                }

                if (!viewInput.includes(brand_id)) {
                    return (
                        <>
                            <Table.Cell>{value}</Table.Cell>
                            <Table.Cell></Table.Cell>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Table.Cell>
                                <Input
                                    size={"mini"}
                                    onChange={handleInputChange}
                                    onKeyPress={event => event.key === 'Enter' ? inputSave(brand_id) : null}
                                    value={value}/>
                                {/* <TextField
                                id="standard-name"
                                label="Name"
                                value={value}
                                margin="normal"
                                onChange={handleInputChange}
                            />*/}
                            </Table.Cell>
                            <Table.Cell style={{textAlign: "center"}}>
                                <CheckCircleOutlineIcon cursor={"pointer"} onClick={() => inputSave(brand_id)}/>
                            </Table.Cell>
                        </>
                    )
                }

            }

            return firstBrands.map((brand, index) => (
                <Table.Row key={brand.id}>
                    <Table.Cell collapsing>
                        <Checkbox defaultChecked={brandIds.includes(brand.id) ? true : false} onChange={() => selectBrand(brand.id)} slider/>
                    </Table.Cell>
                    <Table.Cell>{brand.id}</Table.Cell>
                    <Table.Cell style={{width: 200}}>{brand.var_title}</Table.Cell>
                    {IndetyInputView(identies, brand.id)}
                    <Table.Cell style={{textAlign: "center"}}>
                        <EditIcon onClick={() => setViewInput([brand.id])} cursor={"pointer"} fontSize={"small"}/>
                    </Table.Cell>
                </Table.Row>
            ))
        }

        if (ecu_id != '') {
            return (
                <>
                    <Grid stackable>
                        <GridRow>
                            <GridColumn width={10}>
                                <h4>{t('Brand List')}</h4>
                            </GridColumn>
                            <GridColumn width={6}>
                                <Input value={kw} loading={loadingKw} onChange={_.debounce(handleSearchChange, 500, {
                                    leading: true,
                                })} icon='search' placeholder={t('Search')}/>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn>

                                <Table celled compact definition>
                                    <Table.Header fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell>ID</Table.HeaderCell>
                                            <Table.HeaderCell>{t('NAME')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('IDENTIFICATION')}</Table.HeaderCell>
                                            <Table.HeaderCell></Table.HeaderCell>
                                            <Table.HeaderCell></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <RowView/>
                                    </Table.Body>
                                </Table>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </>
            )
        } else {
            return <>{t('Select ECU')}</>
        }
    }
    const newEcuPage = () => {
        history.push('/ecu/admin/ecu/new')
    }
    const Context = () => {
        const SelectEcus = () => {
            if (loading) {
                return <img src={load}/>
            } else {
                return <>
                    <Dropdown
                        search
                        selection
                        options={ecus}
                        onChange={handleChanges}
                        placeholder={t('Select ECU')}
                        value={ecu_id}
                    />
                </>
            }
        }
        return (
            <Grid stackable>
                <Grid.Column width={8}>
                    <Segment>
                        <Grid>
                            <Grid.Column width={12}>
                                <SelectEcus/>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <div style={{textAlign: "right"}}>
                                    <Fab size="small" color="primary" aria-label="add" onClick={newEcuPage}>
                                        <AddIcon/>
                                    </Fab>
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Segment>
                        <BrandsList/>

                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
    return <Main Context={Context} pageTitle={t('ECU Types And Car Brands')}/>
}
export default AdminEcus;
